import React, { useContext } from "react"
import Layout from "../../components/Layout"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"
import styled from "styled-components"
import { myContext } from "../../ThemeContext"
import BusinessOffers from "../../components/business/BusinessOffers"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SEO from "../../components/seo"

export default function About() {
  return (
    <Layout headerType="business">
      <SEO title="Business Leasing · Compulease" />
      <BusinessOffers />
      <AboutUs />
      <Benefits />
    </Layout>
  )
}

const AboutUsSection = styled.div`
  margin-top: 1rem;
  .title {
    position: relative;
    margin: 2rem 0 2rem 0;
    &.with-desc .title-top {
      margin-bottom: 52px;
    }
    h5 {
      font-weight: 500;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 5px;
      color: ${props => props.theme.secondaryBlue};
    }
    h2.title {
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 0;
      color: ${props => props.theme.primaryBlue};
    }
    .title-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 15px;
    }
    .title-top {
      margin-bottom: 40px;
      position: relative;
      &:before {
        width: 52px;
        bottom: -20px;
        height: 4px;
        left: 0;
        top: auto;
        position: absolute;
        content: "";
        border: 4px solid transparent;
        border-bottom: 0;
        border-top-color: ${props => props.theme.primaryBlue};
      }
    }
  }
  .feature-image {
    display: flex;
    align-items: center;
    height: 100%;
  }
  ul {
    color: ${props => props.theme.primaryBlue};
    list-style: none;
    padding: 0;
    padding-left: 0;
    font-size: 18px;
    li {
      margin-bottom: 0.75rem;
    }
    span {
      color: ${props => props.theme.darkGrey};
      padding-left: 1rem;
    }
  }
  .overlay {
    top: 100px;
    padding: 28px 30px;
    border-radius: 15px;
    line-height: 31px;
    width: 210px;
    text-align: center;
    position: absolute;
    right: 0px;
    background-color: ${props => props.theme.primaryBlue};
    p {
      color: white;
      font-size: 22px;
      margin-bottom: 0;
    }
  }
`
const Letter = styled.section`
  background-color: #f7f9fe;
  margin: 3rem 0;
  padding: 40px 15px;
  padding: 3rem;
  .title {
    margin-bottom: 40px;
    position: relative;
    &:before {
      width: 52px;
      bottom: -20px;
      height: 4px;
      left: 0;
      top: auto;
      position: absolute;
      content: "";
      border: 4px solid transparent;
      border-bottom: 0;
      border-top-color: ${props => props.theme.primaryBlue};
    }
  }
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const HorizontalLine = styled.div`
  height: 1px;
  border-top: 1px solid #ebebeb;
  display: block;
  position: relative;
  width: 100%;
  margin: 2rem 0;
`

function AboutUs() {
  const theme = useContext(myContext)
  const data = useStaticQuery(graphql`
    query businessHome {
      FeatureImage: file(
        relativePath: { eq: "business/AdobeStock_88922803.jpeg" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <AboutUsSection theme={theme}>
      <Container>
        <Row>
          <Col lg="6">
            <div className="title with-desc">
              <div className="title-top">
                <h5>Computer Leasing and Finance Solutions for UK Business</h5>
                <h2>Welcome to Compulease™</h2>
              </div>
              <p>
                Welcome to the Compulease™ direct web-site dedicated to
                providing high quality, cost efficient computer leasing and IT
                solutions for business, enabling you to keep your business up to
                date with the latest technology as it develops, without
                affecting your computer finance budget.
              </p>
              <p>
                You can be confident, with our unique approach to design,
                implementation and customer services that you will be delivered
                a computer rental solution which provides you with “complete
                peace of mind,” and with our guaranteed “protection against
                equipment obsolescence” your business will never have to make do
                with old technology again.
              </p>
            </div>
          </Col>
          <Col lg="6">
            <Img
              fluid={data.FeatureImage.childImageSharp.fluid}
              className="feature-image"
              alt="null"
            />
            <div className="overlay">
              <p>The UK’s No.1 Computer Leasing Company</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Letter theme={theme}>
              <div className="">
                <div className="">
                  <div className="">
                    <h3 className="title">
                      Compulease™ is the UK's largest technology and computer
                      leasing company and we are committed to providing a
                      service which enables companies to keep in touch with
                      equipment technology as it develops, in the most cost
                      efficient way
                    </h3>
                  </div>
                  <div className="title-desc">
                    <p>
                      Our unique and flexible computer finance service has
                      already benefited many organisations, enabling them to get
                      the most out of their I.T budgets, by avoiding computer
                      obsolescence, reducing capital outlay, improving cash-flow
                      management, allowing accurate forward budgeting and
                      avoiding unexpected capital expenditure.
                    </p>
                    <p>
                      Compulease™ is unlike any traditional computer leasing
                      company. Our business is driven by our customer's ongoing
                      requirements to change with developing technology so they
                      can retain their competitive edge. We do not simply
                      finance a piece of equipment and then forget about it.
                      Your Compulease™ rental automatically qualifies you for
                      replacement updated equipment every three years, with no
                      increase to your original computer rental cost. And at the
                      time of upgrade, you keep your original equipment,
                      absolutely FREE of charge.
                    </p>
                    <p>
                      Our aim is to meet the customer's business needs with the
                      largest range of I.T products and services. If you have a
                      specific requirement and you can't find it in our range,
                      feel free to call one of our customer support team and
                      we'll source it for you, so you will still receive the
                      benefit of our purchasing power.
                    </p>
                    <p>
                      Compulease™ is all about maximising the performance of
                      your investment and we are committed to providing clients
                      unrivalled and impartial advice on a complete range of
                      goods and services and to developing long term business
                      relationships.
                    </p>
                  </div>
                </div>
                <HorizontalLine />
                <Row>
                  <Col lg={{ span: 3, offset: 9 }}>
                    <div className="featured-icon-box without-icon text-left pt-15">
                      <div className="featured-content">
                        <div className="featured-title">
                          <h5>James Knight</h5>
                        </div>
                        <div className="featured-desc">
                          <p>james@compulease.co.uk</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Letter>
          </Col>
        </Row>
      </Container>
    </AboutUsSection>
  )
}

const Styling = styled.section`
  margin: 4rem 0;
  h5 {
    font-weight: 500;
    text-transform: none;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
    color: #2b53d6;
  }
  ul {
    list-style: none;
    padding: 0;
    padding-left: 0;
    li {
      color: #6e6e6e;
      font-size: 16px;
      padding-left: 22px;
      list-style: none;
      position: relative;
      padding-bottom: 10px;
      svg {
        position: absolute;
        left: 0px;
        top: 5px;
        color: #2d4a8a;
      }
    }
  }
`

function Benefits() {
  function ListItem(props) {
    return (
      <li>
        <FontAwesomeIcon icon={faCheckCircle} />
        <span>{props.children}</span>
      </li>
    )
  }

  const benefits = [
    [
      "Dedicated “Customer First” program",
      "Personable service which will provide you with expert and impartial advice - no automated answer phone systems",
      "Choice of a large range of all leading brand IT equipment",
      "Comprehensive “after sales” service package",
      "On-site manufacturer’s warranties",
      "Optional low cost comprehensive Equipment Protection Cover (EPC)",
      "Optional Maintenance Packages providing you dedicated IT support",
    ],
    [
      "Cost Effective - no unexpected capital outlays",
      "Guaranteed protection against computer obsolescence",
      "Continuous updated equipment with no increase in your current rental",
      "When you update your equipment, you may be able to retain your original goods as part of the new solution",
      "All the benefits of traditional leasing",
      "Optional built-in warranty protection",
      "Allows business to keep in continuous touch with technology as it develops",
    ],
  ]

  return (
    <Styling>
      <Container>
        <Row>
          <Col>
            <h5>The Benefits of Leasing with Compulease</h5>
            <h2>
              You, the client, are the most important person to our organisation
              and we have developed our whole service on being able to deliver a
              benefits package which will make a positive contribution to your
              business
            </h2>
            <p>
              As a valued client, you will be able to take advantage of the
              following Compulease™ benefits
            </p>
            <Row>
              <Col md="6">
                <ul>
                  {benefits[0].map((value, index) => {
                    return <ListItem key={index}>{value}</ListItem>
                  })}
                </ul>
              </Col>
              <Col md="6">
                <ul>
                  {benefits[1].map((value, index) => {
                    return <ListItem key={index}>{value}</ListItem>
                  })}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Styling>
  )
}
